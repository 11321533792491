import { useNavigate } from "react-router-dom"


export default function Navbar() {

    const navigate = useNavigate()
    const handleLogout = () => {
        localStorage.removeItem('iececotoken')
        navigate('/login')
    }
    return (
        <div className='row d-flex justify-content-between mb-5 p-1'>
            <div className='col-5 col-sm-5 col-md-4 col-lg-3 home-page-ecowrap-logo'>
                <img src='asset/ecowrap-logo.png' alt='ecowrap-logo' />
            </div>
            <div className='col-1 col-md-1  me-5'>
                <button
                    className='btn btn-secondary '
                    onClick={handleLogout}
                >Logout</button>
            </div>
        </div>
    )
}