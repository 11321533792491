import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import Login from './Login'
import Home from './Home'
import Survey from './Survey';
// import ForgotPassword from './pages/ForgotPassword';
function App() {
  const baseUrl = 'https://apis.ecowrap.in/b2g/internal-team/api/v1'
  // const baseUrlSurvey = 'https://apis.ecowrap.in/b2g/eco-admin'
  // const baseUrl = 'http://localhost:3000/gov/admin'
  const authTokenData = localStorage.getItem('iececotoken')
  const [authToken, setAuthToken] = useState(authTokenData)

  return (
    <div className='App'>
      <Router>
        <Routes>

          <Route exact path='/login' element={<Login baseUrl={baseUrl} setAuthToken={setAuthToken} />} />
          <Route exact path='/' element={<Home baseUrl={baseUrl} authToken={authToken} />} />
          <Route exact path='/survey' element={<Survey baseUrl={baseUrl} authToken={authToken} />} />

          {/* <Route exact path='forgotPassword' element={<ForgotPassword baseUrl={baseUrl} />} /> */}
        </Routes>
      </Router>
    </div >
  );
}

export default App;
